// 硬件详情
<template>
  <div class="content">
    <div style="font-size: 30px"></div>

    <div class="top">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="margin-left: 10px; line-height: 21px"
      >
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product' }"
          >产品与服务</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/product?id=9' }"
          >硬件产品</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ hardwareDetail.devName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading="Loading" class="goodsUpload">
      <!-- 商品图片 -->
      <div class="leftSmallImg" v-if="Products">
        <div
          class="item"
          v-for="(item, index) in Products"
          :key="index"
          @click="ProductsClick(index)"
        >
          <img
            :src="Pindex == index ? item.imgUrlHL : item.imgUrl"
            alt="商品图片"
            width="90px"
            height="90px"
          />
        </div>
      </div>

      <div class="mainImage">
        <!-- 图片 -->

        <el-image
          class="imageItem"
          title="点击大图预览"
          :src="imageUrl"
          fit="contain"
        />

        <!-- 鼠标层罩 -->
        <div
          v-show="coverLayerShow"
          class="coverLayerMouse"
          :style="coverLayerStyle"
        ></div>
        <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
        <div
          class="coverLayerMaskTop"
          @mouseenter="enterHandler"
          @mousemove="moveHandler"
          @mouseout="outHandler"
        ></div>
        <!-- 放大的图片 -->
        <div v-show="coverLayerShow" class="coverLayerRight">
          <el-image
            :style="coverLayerImg"
            class="coverLayerRightImg"
            :src="imageUrl"
            fit="contain"
          />
        </div>
      </div>

      <span class="span">
        <img
          src="../assets/icon/放大镜1.png"
          width="20px"
          height="20px"
          class="img2"
          alt="放大镜"
        />
        <div v-viewer>
          <img
            :src="imageUrl"
            alt="放大的图片"
            width="20px"
            height="20px"
            class="img1"
          />
        </div>
      </span>

      <!-- 型号介绍-->
      <div class="imgright" v-show="show">
        <p>{{ hardwareDetail.devName }}</p>
        <p>
          <span style="color: #0249a1">型号: </span>
          {{ hardwareDetail.devModel }}
        </p>
        <div style="width: 587px; height: 1px; background-color: #c1c1c1"></div>
        <p class="product_feature">产品特点</p>
        <p style="line-height: 30px">{{ hardwareDetail.devTrait }}</p>
      </div>
    </div>
    <!-- 技术参数 -->
    <div class="Parameter">
      <div class="Paramter_technology">
        <img src="../assets/HardwareDetail/Parameter.jpg" alt="技术参数图片" />
        <p>技术参数</p>
      </div>
      <div
        class="technical_parameter"
        v-for="(item, index) in hardwareDetailContent"
        :key="index"
      >
        <img src="../assets/HardwareDetail/bingo.jpg" alt="排列图片" />
        <p v-html="item" style="line-height: 30px"></p>
        <div></div>
      </div>

      <!-- 人机交互页面-->

      <!-- <div class="man-machine">
        <p>人机交互界面</p>
        <div class="man-machine_interaction">
          <div>
            <img src="../assets/HardwareDetail/computed.jpg" alt="" />
            <p>{{ hardwareDetail.devScreen }}</p>
          </div>
          <div>
            <img src="../assets/HardwareDetail/Battery.jpg" alt="" />
            <p>{{ hardwareDetail.devPower }}</p>
          </div>
          <div>
            <img src="../assets/HardwareDetail/dpi.jpg" alt="" />
            <p>{{ hardwareDetail.devResolvingPower }}</p>
          </div>
        </div>
      </div> -->
      <!-- 智能控制器 -->
      <!-- <div class="intelligent_controller">
        <p>智能控制器</p>
        <p
          v-for="(item, index) in hardwareDetailDevControl"
          :key="index"
          v-html="item"
        ></p>
      </div> -->
      <!-- 应用场景 -->
      <!-- <div class="application_scenarios">
        <div>
          <img src="../assets/HardwareDetail/application.jpg" alt="" />
          <p class="application">应用场景</p>
        </div>
        <p class="scenarios">{{ hardwareDetail.devScene }}</p>
      </div> -->
    </div>
    <div class="Parameter" style="margin-top: 50px">
      <div class="Paramter_technology">
        <img src="../assets/HardwareDetail/pay.png" alt="技术参数图片" />
        <p>立即购买</p>
      </div>
      <div class="buy">
        <a
          v-for="item in hardwareDetail.shopLinks"
          :key="index"
          :href="item.link"
          target="“_blank”"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="Parameter" style="margin-top: 50px">
      <div class="Paramter_technology">
        <img src="../assets/HardwareDetail/cs.png" alt="技术参数图片" />
        <p>推荐产品</p>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in Hardware" :key="index">
          <a :href="`#/HardwareDetail/${item.devId}`">
            <div class="devImg">
              <img :src="item.devImg" alt="硬件产品展示图" />
            </div>
            <div class="text">
              <p>{{ item.devName }}</p>
              <button>详情参数</button>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="Parameter" style="margin-top: 50px">
      <div class="Paramter_technology1">
        <img src="../assets/HardwareDetail/wz.png" alt="技术参数图片" />
        <p>推荐文章</p>
      </div>
      <div v-for="(item, index) in hotContent" :key="index" class="hotlist">
        <!-- <a :href="`/NewsDetail/${item.cnId}`" style="color: #000"> -->
        <a :href="`#/NewsDetail/${item.cnId}`">
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ item.cnTit }}
          </p>
          <!-- </a
            > -->
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);
export default {
  name: "GoodsUpload",
  data() {
    return {
      // 商品图片
      imageUrl: "",
      Loading: false,
      coverLayerStyle: {
        transform: "",
      },
      coverLayerImg: {},
      coverLayerShow: false,
      show: 1,
      detailId: "",
      hardwareDetail: "",
      hardwareDetailContent: "",
      hardwareDetailDevControl: "",
      Pindex: 0,
      urlList: "",
      Products: [
        // {
        //   imgUrl: require("../assets/HardwareDetail/imga.jpg"),
        //   imgUrlHL: require("../assets/HardwareDetail/imga.jpg"),
        // },
        // {
        //   imgUrl: require("../assets/HardwareDetail/imgb.jpg"),
        //   imgUrlHL: require("../assets/HardwareDetail/imgb.jpg"),
        // },
        // {
        //   imgUrl: require("../assets/HardwareDetail/imgc.jpg"),
        //   imgUrlHL: require("../assets/HardwareDetail/imgc.jpg"),
        // },
        // {
        //   imgUrl: require("../assets/HardwareDetail/imgd.jpg"),
        //   imgUrlHL: require("../assets/HardwareDetail/imgd.jpg"),
        // }
        // ,
        { imgUrl: " ", imgUrlHL: " " },
        { imgUrl: " ", imgUrlHL: " " },
        { imgUrl: " ", imgUrlHL: " " },
        { imgUrl: " ", imgUrlHL: " " },
      ],
      Hardware: [],
      hotContent: [],
      content: [],
      hot: [],
    };
  },
  watch: {
    $route(to, from) {
      // 监听路由是否变化
      if (to.params.id != from.params.id) {
        this.id = to.params.id;
        this.$router.go(0);
      }
    },
  },
  created() {
    this.detailId = this.$route.params.id;
    if (this.detailId) {
      // this.$router.go(0)
    }
    this.getqueryById(this.detailId);
    console.log(this.$route);
    this.$API.getArticleListAll().then((res) => {
      this.content = res.data;
      console.log("vvvv", res);
      res.data.map((item, index) => {
        this.hot.push(item.cnViews);
      });
      console.log(this.hot);
      this.hot.sort((a, b) => {
        return b - a;
      });
      res.data.map((item, index) => {
        if (this.hot[0] == item.cnViews) {
          // console.log(item);
          this.hotContent[0] = item;
        }
        if (this.hot[1] == item.cnViews) {
          this.hotContent[1] = item;
        }
        if (this.hot[2] == item.cnViews) {
          this.hotContent[2] = item;
        }
        if (this.hot[3] == item.cnViews) {
          this.hotContent[3] = item;
        }
        if (this.hot[4] == item.cnViews) {
          this.hotContent[4] = item;
        }
      });
      console.log(this.hotContent);
    });
  },
  mounted() {
    this.getHardware();
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      // 层罩及放大空间的显示
      this.coverLayerShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      this.show = 0;
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 200) {
        topX = 200;
      }
      if (topY > 200) {
        topY = 200;
      }
      // 通过 transform 进行移动控制
      this.coverLayerStyle.transform = `translate(${topX}px,${topY}px)`;
      this.coverLayerImg.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      this.show = 1;
      // 控制层罩与放大空间的隐藏
      this.coverLayerShow = false;
    },
    // 根据id获取详情
    getqueryById(val) {
      let data = {
        devId: val,
      };
      this.$API.getHardwareDetail(data).then((res) => {
        console.log("res :详情>> ", res);
        this.hardwareDetail = res.data;
        console.log(this.hardwareDetail.devSkill);
        console.log(this.hardwareDetail);
        this.urlList = this.hardwareDetail.fileVoList;
        this.urlList.map((item, index) => {
          this.Products.map((ite, ind) => {
            ind == index
              ? ((ite.imgUrl = item.url), (ite.imgUrlHL = item.url))
              : "";
          });
        });
        this.imageUrl = this.hardwareDetail.devImg;
        this.hardwareDetailContent =
          this.hardwareDetail.devSkill.split("<br/>");

        this.hardwareDetailDevControl =
          this.hardwareDetail.devControl.split("<br/>");
      });
    },
    ProductsClick(index) {
      this.Pindex = index;
      this.Products.map((item, index) => {
        this.Pindex == index ? (this.imageUrl = item.imgUrl) : "";
      });
    },
    getHardware() {
      this.$API.getHardware().then((res) => {
        // this.Hardware = res.data.slice(0, 4);
        this.Hardware = res.data;
        console.log(this.Hardware, "硬件列表");
        function getArrayItems(arr, num) {
          var temp_array = new Array();
          for (var index in arr) {
            temp_array.push(arr[index]);
          }
          var return_array = new Array();
          for (var i = 0; i < num; i++) {
            if (temp_array.length > 0) {
              var arrIndex = Math.floor(Math.random() * temp_array.length);
              return_array[i] = temp_array[arrIndex];
              temp_array.splice(arrIndex, 1);
            } else {
              break;
            }
          }
          return return_array;
        }
        var ArrList = this.Hardware;
        this.Hardware = getArrayItems(ArrList, 4);
        console.log(this.Hardware, "this.hardware");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 50px 0;
  width: 60% !important;
  margin: 50px auto !important;
  .top {
    margin-bottom: 40px;
  }
  .goodsUpload {
    display: flex;
    .leftSmallImg {
      div {
        width: 90px;
        height: 90px;
        border: 1px solid #c1c1c1;
        margin-bottom: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .imgright {
      margin-left: 80px;
      p:nth-child(1) {
        font-size: 33px;
        color: #0249a1;
      }
      p:nth-child(2) {
        margin: 20px 0 15px 0;
      }
      .product_feature {
        color: #0249a1;
        margin: 20px 0;
      }
    }
  }
  .Parameter {
    padding-left: 30px;
    padding-top: 50px;
    .Paramter_technology {
      display: flex;
      margin-bottom: 40px;

      img {
        width: 25px;
        height: 25px;
      }
      p {
        font-size: 25px;
        margin-top: -4px;
        margin-left: 10px;
        color: #0249a1;
      }
    }
    .Paramter_technology1 {
      display: flex;
      margin-bottom: 40px;
      img {
        width: 30px;
        height: 30px;
      }
      p {
        font-size: 25px;
        margin-top: -2px;
        margin-left: 10px;
        color: #0249a1;
      }
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        width: 23%;

        margin: 2% 1%;
        height: 400px;
        background-color: #004aa1;
        border-radius: 10px;
        box-shadow: 0 0 10px 2px rgb(210, 210, 210);
        .devImg {
          width: 100%;
          height: 300px;
          // border-radius: 8px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: #fff;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .text {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          color: #fff;
          p {
            width: 80%;
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: 600;
          }
          button {
            cursor: pointer;
            display: block;
            padding: 5px 40px;
            border: 1px solid #fff;
            border-radius: 20px;
            color: white;
            background-color: #004aa1;
          }
          button:hover {
            color: black;
            background-color: #fff;
          }
          span:hover {
            background-color: #fff;
            color: #004aa1;
          }
        }
      }
      .gotoHardwareDetail {
        cursor: pointer;
        display: block;
        padding: 5px 40px;
        border: 1px solid #fff;
        border-radius: 20px;
        a {
          color: white;
        }
        background-color: #004aa1;
        line-height: 30px;
        margin-top: 20px;
      }
      .gotoHardwareDetail:hover {
        a {
          color: black;
        }
        // background-color: #fff;
      }
    }
    .hotlist {
      a {
        color: #000;
      }
      a:hover {
        color: #004aa1;
      }
      p {
        line-height: 30px;
      }
    }
    .buy {
      a {
        background-color: #ff6000;
        padding: 10px 40px;
        text-align: center;
        line-height: 12px;
        border-radius: 20px;
        margin-right: 10%;
        color: #fff;
      }
    }
    .technical_parameter {
      display: flex;
      border-bottom: 1px solid #c1c1c1;
      margin-top: 15px;
      img {
        width: 17px;
        height: 17px;
        margin-left: 5px;
      }
      p {
        margin-bottom: 15px;
        margin-left: 15px;
        margin-top: -3px;
      }
    }
    .man-machine {
      margin-top: 30px;
      p:nth-child(1) {
        font-size: 20px;
        color: #0249a1;
      }
      .man-machine_interaction {
        display: flex;
        div {
          width: 30%;
          text-align: center;
        }
        div:nth-child(1) {
          img {
            margin-top: 40px;
          }
          p {
            margin-top: 20px;
          }
        }
        div:nth-child(2) {
          img {
            margin-top: 50px;
          }
          p {
            margin-top: 28px;
          }
        }
        div:nth-child(3) {
          img {
            margin-top: 35px;
          }
          p {
            margin-top: 22px;
          }
        }
      }
    }
    .intelligent_controller {
      margin-top: 40px;
      p {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      p:nth-child(1) {
        color: #0249a1;
        font-size: 20px;
      }
    }
    .application_scenarios {
      div {
        display: flex;
        margin-top: 40px;
        img {
          width: 25px;
          height: 25px;
          margin-top: 10px;
        }
        .application {
          font-size: 30px;
          margin-left: 8px;
          color: #0249a1;
        }
      }
      .scenarios {
        margin-top: 30px;
      }
    }
  }
}

// 放大镜样式
.mainImage {
  width: 400px;
  height: 400px;
  position: relative;
}
.mainImage .imageItem {
  border: 1px solid #dcdcdc;
  width: 400px;
  height: 400px;
  position: relative;
}
/* 放大的图片，通过定位将左上角定位到(0,0) */
.coverLayerRightImg {
  display: inline-block;
  width: 800px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
}
/* 右边的区域图片放大空间 */
.coverLayerRight {
  background-color: #ffffff;
  width: 400px;
  height: 400px;
  border: 1px solid #dcdcdc;
  position: relative;
  overflow: hidden;
  position: absolute;
  left: 412px;
  top: 0;
  z-index: 1001;
}
/* 一个最高层层罩 */
.coverLayerMaskTop {
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  cursor: move;
}
/* 层罩，通过定位将左上角定位到(0,0) */
.coverLayerMouse {
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
}
.img1 {
  margin-top: 410px;
  margin-left: -25px;
  cursor: pointer;
  // display: none;
  opacity: 0;
}
.img2 {
  margin-top: 410px;
  margin-left: -25px;
  // cursor: pointer;
  // display: none;
}
.span {
  position: relative;
  .img2 {
    position: absolute;
    top: 0;
  }
}
</style>